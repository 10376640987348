<!--
 * =====================================================================
 * 작 업 명  : 로그인 콜백 처리
 * 파 일 명  : LoginCallback.vue
 * 변경이력
 * 2022-02-14 / 정태화 / 최초작성
 * 2023-08-30 / 이성준 / 토큰(쿠키) 타임아웃 시간 12시간으로 변경
 * =====================================================================
 -->
<template>
   <div></div>
</template>
<script>
    import { $global, $session } from "dot-framework";
    import { router } from "@/routes/index.js";

    export default
    {
        // 최초 호출
        mounted()
        {
            this.callback();
        },
        methods:
        {
            // 로그인 콜백처리
            callback()
            {
                // 통합인증에서 넘겨받는 파라미터
                // ?a=[access token]&r=[refresh token]&u=[user_no]
                 let param = $global.getUrlParams();
                 if (param) {
                    // 토큰 및 타임아웃 설정(분)
                    $session.setToken(param, 720);
                    // 메뉴권한 설정
                    this.$store.dispatch("setMenuList");
                    // 루트 페이지로 이동
                    router.push({name: "root"});
                 }
            }
        },
    }
</script>
<style>
</style>
